import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function LanguageCard(props) {
    return (
        <Card sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column'
        }}>
            <CardContent>
                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                    {props.topSub}
                </Typography>
                <Typography sx={{ fontSize: 18 }} component="div">
                    {props.title}
                </Typography>
                <Typography sx={{ fontSize: 12, mb: 1.5 }} color="text.secondary">
                    {props.bottomSub}
                </Typography>
                <Typography variant="body2">
                    {props.text}
                </Typography>
            </CardContent>
            <CardActions>
                <Link href={props.url} underline="none" target="_blank" rel="noopener" color="inherit">
                    <Button size="small">View</Button>
                </Link>
            </CardActions>
        </Card>
    );
}

export default LanguageCard;