import { Helmet } from 'react-helmet';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import CertCard from '../components/CertCard';
import OppositeTimeline from '../components/OppositeTimeline';
// import Newsletter from '../components/Newsletter';
import Footer from '../components/Footer';

import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { differenceInYears, parse } from 'date-fns';


import blog_banner from './blog_banner.png';


function Home() {

    const calculateAge = (dob) => {
        const date = parse(dob, "dd/MM/yyyy", new Date())
        const age = differenceInYears(new Date(), date)
        return age
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home - Kevin Azemi</title>
                <meta name="description" content="Kevin Azemi personal website." />
            </Helmet>

            <Box sx={{
                width: '100%',
                height: 500,
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Typography id="title" variant="h2" gutterBottom component="div" sx={{ textAlign: 'center', fontWeight: 'bold', m: 1 }}>
                    Hi, I'm Kevin.
                </Typography>
            </Box>
            <Box id='centerDiv'>
                <Box sx={{ mt: 5 }}>
                    <Typography className="subTitle" variant="h4" gutterBottom component="div" sx={{ fontWeight: 'medium', m: 1 }}>
                        Welcome 👋
                    </Typography>
                    <Typography variant="body1" gutterBottom align="justify">
                        Hey there, I'm Kevin Azemi, a { calculateAge("18/12/2003") } year-old student currently pursuing a degree in Computer Science at the University of Trento.
                        My heart beats for servers, web development, network engineering and homelab things.
                        Beyond the tech world, you'll find me hitting the gym, cruising on my road bike, and tearing up the slopes while skiing. I'm all about that active lifestyle.
                        I'm not just a coder and tech enthusiast; I'm a dynamic individual who seeks the perfect balance between the digital and the physical.
                        Feel free to reach out for a chat about potential collaborations or any questions you may have. Looking forward to it!
                    </Typography>
                </Box>

                <Box sx={{ mt: 5 }}>
                    <Typography className="subTitle" variant="h4" gutterBottom component="div" sx={{ fontWeight: 'medium', m: 1 }}>Experience and Formation</Typography>
                    <OppositeTimeline />
                </Box>

                <Box sx={{ mt: 5 }}>
                    <Typography className="subTitle" variant="h4" gutterBottom component="div" sx={{ fontWeight: 'medium', m: 1 }}>Licenses and certifications</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} style={{display: 'flex'}}>
                            <CertCard
                                topSub="Coursera"
                                title="Google Cloud Digital Leader Training Specialization"
                                bottomSub="WRNDFSACGQQ2"
                                text="The Cloud Digital Leader training consists of a course series designed to give you foundational knowledge about cloud technology and data."
                                url="https://www.coursera.org/account/accomplishments/certificate/WRNDFSACGQQ2"
                            />
                        </Grid>
                        <Grid item xs={6} style={{display: 'flex'}}>
                            <CertCard
                                topSub="MongoDB"
                                title="M001: MongoDB Basics"
                                bottomSub="f0564c9b-3a85-4018-b13f-38d6d8d6dda0"
                                text="In this course you will learn how to set up your database and start exploring different ways to search, create, and analyze your data with MongoDB. We will cover database performance basics, and discover how to get started with creating applications and visualizing your data."
                                url="https://university.mongodb.com/course_completion/f0564c9b-3a85-4018-b13f-38d6d8d6dda0"
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{
                        textAlign: 'center',
                        opacity: 0.6,
                        mt: 3
                    }}>
                        <Link href="#/certifications" underline="none" rel="noopener" color="inherit">
                            <KeyboardArrowDownIcon />
                        </Link>
                    </Box>
                </Box>

                <Box sx={{ mt: 5 }}>
                    <Typography className="subTitle" variant="h4" gutterBottom component="div" sx={{ fontWeight: 'medium', m: 1 }}>Languages</Typography>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ textAlign: 'center' }}>
                        <Grid item xs={6}>
                            <Typography gutterBottom component="div" sx={{ fontSize: 24, m: 1 }}>Italian</Typography>
                            <Typography gutterBottom component="div" sx={{ opacity: 0.6, m: 1 }}>Native or bilingual knowledge</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom component="div" sx={{ fontSize: 24, m: 1 }}>English</Typography>
                            <Typography gutterBottom component="div" sx={{ opacity: 0.6, m: 1 }}>Complete professional knowledge</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom component="div" sx={{ fontSize: 24, m: 1 }}>Albanian</Typography>
                            <Typography gutterBottom component="div" sx={{ opacity: 0.6, m: 1 }}>Professional knowledge</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom component="div" sx={{ fontSize: 24, m: 1 }}>French</Typography>
                            <Typography gutterBottom component="div" sx={{ opacity: 0.6, m: 1 }}>Basic knowledge</Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ mt: 5 }}>
                    <Typography className="subTitle" variant="h4" gutterBottom component="div" sx={{ fontWeight: 'medium', m: 1 }}>Contact</Typography>
                    <Typography gutterBottom component="div" sx={{ m: 1 }}>You can just get in touch with me in one of these ways:</Typography>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ textAlign: 'center' }}>
                        <Grid item xs={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <LinkedInIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <Link href='https://www.linkedin.com/in/kevin-azemi' underline="none" target="_blank" rel="noopener" color="inherit">
                                    <ListItemText
                                        primary="Kevin Azemi"
                                    />
                                </Link>
                            </ListItem>
                        </Grid>

                        <Grid item xs={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <InstagramIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <Link href='https://www.instagram.com/kevin.azemi' underline="none" target="_blank" rel="noopener" color="inherit">
                                    <ListItemText
                                        primary="kevin.azemi"
                                    />
                                </Link>
                            </ListItem>
                        </Grid>

                        <Grid item xs={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <GitHubIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <Link href='https://github.com/Klay4' underline="none" target="_blank" rel="noopener" color="inherit">
                                    <ListItemText
                                        primary="Klay4"
                                    />
                                </Link>
                            </ListItem>
                        </Grid>

                        <Grid item xs={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <EmailIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <Link href='mailto:contact@kevinazemi.com' underline="none" target="_blank" rel="noopener" color="inherit" style={{  whiteSpace: "unset", wordBreak: "break-all" }} >
                                    <ListItemText
                                        primary="contact@kevinazemi.com"
                                    />
                                </Link>
                            </ListItem>
                        </Grid>
                    </Grid>
                </Box>

                <Box id='blogBox'>
                    <Link href="https://blog.kevinazemi.com" target="_blank" rel="noopener">
                        <img src={blog_banner}  alt="Blog banner"/>
                    </Link>
                </Box>

                {/* <Box sx={{ mt: 5 }}>
                    <Typography className="subTitle" variant="h4" gutterBottom component="div" sx={{ fontWeight: 'medium', m: 1, mb: 2 }}>Newsletter</Typography>
                    <Newsletter />
                </Box> */}

                <Footer />
            </Box>
        </>
    );
  }
  
  export default Home;
  