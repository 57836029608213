import { Helmet } from 'react-helmet';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function NoPage() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>404 - Kevin Azemi</title>
                <meta name="description" content="Page Not Found!" />
            </Helmet>

            <Box sx={{
                    width: '100%',
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                    backgroundColor: 'white',
                    
            }}>
                <Typography variant="h1" component="h1" sx={{ textAlign: 'center', fontWeight: 'bold', m: 1, mt: 30 }}>
                    404
                </Typography>

                <Box sx={{ textAlign: 'center' }}>
                    <Link href='/' underline="none" rel="noopener" color="inherit">
                        <Typography variant="subtitle1" component="subtitle1">
                            Go back
                        </Typography>
                    </Link>
                </Box>
                
            </Box>
        </>
    );
}

export default NoPage;