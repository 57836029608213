import Typography from '@mui/material/Typography';

function Footer() {
    return (
        <>
            <Typography gutterBottom component="div" sx={{ textAlign: 'center', color: 'white', opacity: 0.4, mt: 5, mb: 2 }}>
                Made with &hearts; by Kevin Azemi
            </Typography>
        </>
    );
}

export default Footer;