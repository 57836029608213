import { Helmet } from 'react-helmet';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import CertCard from '../components/CertCard';
import Footer from '../components/Footer';

function CertPage() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Certifications - Kevin Azemi</title>
                <meta name="description" content="Certifications list." />
            </Helmet>

            <Box sx={{
                width: '100%',
                height: 500,
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Typography id="title" variant="h2" gutterBottom component="div" sx={{ textAlign: 'center', fontWeight: 'bold', m: 1 }}>
                    Licenses and certifications
                </Typography>
            </Box>

            <Box id='largeCenterDiv'>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4, color: 'lightgrey' }}>
                    <Link underline="hover" color="lightgrey" href="/">
                        Home
                    </Link>
                    <Typography color="white">Certifications</Typography>
                </Breadcrumbs>

                <Box sx={{ mt: 3 }}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ flexGrow: 1 }}>
                        <Grid item xs={12} sm={4} md={4} style={{display: 'flex'}}>
                            <CertCard 
                                topSub="Lacerba.io"
                                title="Introduzione a Google Analytics e Google Tag Manager"
                                bottomSub="a78491"
                                text="The course has been designed for those who need to monitor visits to their profile and / or the performance of their marketing campaigns. Therefore it is aimed at marketers, developers, digital entrepreneurs and startuppers who need to implement constant monitoring to increase the conversion rate of their business."
                                url="https://lacerba.io/certificazioni/a78491.pdf"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} style={{display: 'flex'}}>
                            <CertCard
                                topSub="MongoDB"
                                title="M001: MongoDB Basics"
                                bottomSub="f0564c9b-3a85-4018-b13f-38d6d8d6dda0"
                                text="In this course you will learn how to set up your database and start exploring different ways to search, create, and analyze your data with MongoDB. We will cover database performance basics, and discover how to get started with creating applications and visualizing your data."
                                url="https://university.mongodb.com/course_completion/f0564c9b-3a85-4018-b13f-38d6d8d6dda0"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} style={{display: 'flex'}}>
                            <CertCard
                                topSub="Coursera"
                                title="Deploying SAP on Google Cloud"
                                bottomSub="W2GYBA5W4LB5"
                                text="This course provides a holistic experience of optimally configuring SAP on Google Cloud. Participants will learn to configure SAP on Google Cloud, and what best practices are, leaving the course with actionable experience to configure SAP on Google Cloud and run SAP workloads on Google Cloud."
                                url="https://www.coursera.org/account/accomplishments/certificate/W2GYBA5W4LB5"
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} style={{display: 'flex'}}>
                            <CertCard
                                topSub="Coursera"
                                title="Google Cloud Digital Leader Training Specialization"
                                bottomSub="WRNDFSACGQQ2"
                                text="The Cloud Digital Leader training consists of a course series designed to give you foundational knowledge about cloud technology and data."
                                url="https://www.coursera.org/account/accomplishments/certificate/WRNDFSACGQQ2"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} style={{display: 'flex'}}>
                            <CertCard
                                topSub="Coursera"
                                title="Migrating a Monolithic Website to Microservices on Google Kubernetes Engine"
                                bottomSub="F2VEH5WMFSSB"
                                text="In this Google Cloud Lab, you will deploy an existing monolithic application to a Google Kubernetes Engine cluster, then break it down into microservices. Kubernetes is a platform to manage, host, scale, and deploy containers. Containers are a portable way of packaging and running code. They are well suited to the microservices pattern, where each microservice can run in its own container."
                                url="https://www.coursera.org/account/accomplishments/certificate/F2VEH5WMFSSB"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} style={{display: 'flex'}}>
                            <CertCard
                                topSub="Coursera"
                                title="Google Cloud Fundamentals: Core Infrastructure"
                                bottomSub="8F6DPKGCJTG4"
                                text="This course introduces you to important concepts and terminology for working with Google Cloud Platform (GCP)."
                                url="https://www.coursera.org/account/accomplishments/certificate/8F6DPKGCJTG4"
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} style={{display: 'flex'}}>
                            <CertCard
                                topSub=".Bestr"
                                title="English B2 - Reading and Listening"
                                bottomSub="719AVUUUPK"
                                text="The owner of this Badge is able to read widely independently, adapting style and speed of reading to different texts and purposes and selectively using the appropriate sources for reference and consultation."
                                url="https://bestr.it/verify/719AVUUUPK"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} style={{display: 'flex'}}>
                            <CertCard
                                topSub="University of Trento"
                                title="Workplace Safety - Specific Training: Low Risk"
                                text="The topics addressed in the specific safety training course for workers in low risk companies are related to risk situations, possible damages and accidents and the consequent measures and procedures of prevention and protection characteristic and common of companies belonging to the ATECO sectors of the low risk class."
                                url="https://www.physics.unitn.it/290/formazione-in-materia-di-salute-e-sicurezza"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} style={{display: 'flex'}}>
                            <CertCard
                                topSub="ITI Francesco Severi"
                                title="Safety in the workplace - General Training"
                                text="The course aims to provide the knowledge of specific risks, contextualized for risk categories and productive sectors, in order to contribute to the maintenance of an organized system for prevention and safety at work."
                                url="https://www.inail.it/cs/internet/attivita/prevenzione-e-sicurezza/formazione/catalogo-corsi/corso-per-lavoratori.html"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Footer />
        </>
    );
}

export default CertPage;