import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import SchoolIcon from '@mui/icons-material/School';
import StorageIcon from '@mui/icons-material/Storage';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import * as React from 'react';

export default function CustomizedTimeline() {
  return (
    <Timeline position="alternate">
        <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: 'auto 0', opacity: 0.7 }}
                align="right"
                variant="body2"
            >
                gen 2023
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
                <StorageIcon />
            </TimelineDot>
            <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
                <Link href="https://protonhost.cloud" underline="none" target="_blank" rel="noopener" color="inherit">
                    {'Protonhost'}
                </Link>
            </Typography>
            <Typography>CEO</Typography>
            </TimelineContent>
        </TimelineItem>

        <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: 'auto 0', opacity: 0.7 }}
                variant="body2"
            >
                2022
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
                <LaptopMacIcon />
            </TimelineDot>
            <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
                <Link href="https://www.unitn.it/en" underline="none" target="_blank" rel="noopener" color="inherit">
                    {'University of Trento'}
                </Link>
            </Typography>
            <Typography>Computer Science</Typography>
            </TimelineContent>
        </TimelineItem>

        <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: 'auto 0', opacity: 0.7 }}
                align="right"
                variant="body2"
            >
                2017 - 2022
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
                <SchoolIcon />
            </TimelineDot>
            <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
                <Link href="https://www.itiseveripadova.edu.it/" underline="none" target="_blank" rel="noopener" color="inherit">
                    {'ITI Francesco Severi di Padova'}
                </Link>
            </Typography>
            <Typography>Diploma, Computer Science</Typography>
            </TimelineContent>
        </TimelineItem>
    </Timeline>
  );
}