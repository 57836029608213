import './App.css';

import { HashRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import CertPage from './pages/CertPage';
import NoPage from './pages/NoPage';

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="certifications" element={<CertPage />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
